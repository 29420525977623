<template>
    <div class="preloader" v-if="preloaderStatus > 0">
        <div class="preloader-content">
            <div class="logo"><img src="https://plonq.online/cdn/logo.svg"></div>
            <div class="label" style="font-family: 'SF Pro Display';">Загрузка..</div>
        </div>
    </div>



    <transition name="fade">
        <div class="notify-message" :class="isNotifyMessageError == true ? 'red' : 'green'" v-if="notifyMessage">{{ notifyMessage }}</div>
    </transition>


    <div class="section-main">
        <div class="header">
            <div class="header-mobile">
                <div class="mobile-top">
                    <div class="logo"><img src="../assets/img/logo-panel.svg"></div>
                    <div class="user-block">
                        <div class="email">{{ userEmail }}</div>
                        <div class="logout"><a href="/auth" @click="logout">Выйти из профиля</a></div>
                    </div>
                </div>
                <div class="mobile-search">
                    <div class="search-block" :class="searchActive ? 'active' : ''">
                        <div class="search-input">
                            <input type="text" @input="searchFormDebounce" placeholder="Поиск по адресу / названию / номеру.." v-model="searchInput" @focus="searchSetActive">
                            <div class="search-icon"><img src="../assets/img/search-input.svg"></div>
                        </div>

                        <div class="search-list" v-if="searchListComputed.length > 0">
                            <div class="list-item" @click="searchSelectAddress(searchItem._id)" v-for="(searchItem, index) in searchListComputed" :key="index">
                                <div class="status" :class="getStatusClass(searchItem.status_id)"></div>
                                <div class="address">{{ searchItem.address }}</div>
                                <div class="agent">{{ searchItem.label || searchItem.agent }}</div>
                            </div>
                        </div>
                        <div class="bg-active" @click="searchOutActive"></div>
                    </div>
                </div>
            </div>
            <div class="header-desktop">
                <div class="logo"><img src="../assets/img/logo-panel.svg"></div>
                <div class="search-block" :class="searchActive ? 'active' : ''">
                    <div class="search-input">
                        <input type="text" @input="searchFormDebounce" placeholder="Поиск по адресу / названию / номеру.." v-model="searchInput" @focus="searchSetActive">
                        <div class="search-icon"><img src="../assets/img/search-input.svg"></div>
                    </div>

                    <div class="search-list" v-if="searchListComputed.length > 0">
                        <div class="list-item" @click="searchSelectAddress(searchItem._id)" v-for="(searchItem, index) in searchListComputed" :key="index">
                            <div class="status" :class="getStatusClass(searchItem.status_id)"></div>
                            <div class="address">{{ searchItem.address }}</div>
                            <div class="agent">{{ searchItem.label || searchItem.agent }}</div>
                        </div>
                    </div>
                    <div class="bg-active" @click="searchOutActive"></div>
                </div>
                <div class="user-block">
                    <div class="user-block-control">
                        <div class="email">{{ userEmail }}</div>
                        <div class="admin"><a href="/admin" @click="admin">Админ панель</a></div>
                        <div class="logout"><a href="/auth" @click="logout">Выйти из профиля</a></div>
                    </div>
                    <!--<div class="user-block-settings">
                        <div class="settings-icon" @click="openSettings"><img src="../assets/img/settings.svg"></div>
                    </div>-->
                </div>
            </div>
        </div>

        <div class="general-category">
            <a href="#sales-points" @click="setTabIndex(0)" class="btn-filter th-all" :class="tabIndexActive == 0 ? 'active' : ''">
                <div class="span-group">
                    <span>Точки продаж</span>
                </div>
            </a>

            <a href="#client-alarm" @click="setTabIndex(1)" class="btn-filter th-all" :class="tabIndexActive == 1 ? 'active' : ''">
                <div class="span-group">
                    <span>Замечания клиентов</span>
                    <div class="circle-counter">{{ Object.keys(getFeedbackCount).length > 0 ? getFeedbackCount['feedbackList']['data'].length : '0' }}</div>
                </div>
            </a>
        </div>

        <sales-points v-if="tabIndexActive == 0"></sales-points>
        <client-alarm v-if="tabIndexActive == 1"></client-alarm>

        <transition-group name="fade">
            <export v-if="exportStatus"></export>
            <windowAdd v-if="windowAddStatus"></windowAdd>
            <windowEdit v-if="windowEditStatus"></windowEdit>
        </transition-group>
    </div>
</template>

<script>

import windowAdd from '../components/windowAdd.vue'
import windowEdit from '../components/windowEdit.vue'
import salesPoints from '../components/salesPoints.vue'
import clientAlarm from '../components/clientAlarm.vue'
import Export from '../components/Export.vue'

import { useStore } from "vuex"
import { computed, ref, onMounted, toRaw, reactive} from "vue"

import { debounce } from 'debounce'

export default {
    name: 'Main',
    setup() {
        const store = useStore();

        const preloaderStatus = computed(() => store.state.preloaderStatus)

        const tabIndexActive = ref(0)
        const setTabIndex = (id) => {
            if (!id)
                tabIndexActive.value = 0

            tabIndexActive.value = id
        }

        const windowAddStatus = computed(() => store.state.windowAddStatus)
        const windowEditStatus = computed(() => store.state.windowEditStatus)
        const exportStatus = computed(() => store.state.exportStatus)

        const searchInput = ref(null)
        const searchSetActive = () => { store.state.searchActiveStatus = true }
        const searchOutActive = () => { store.state.searchActiveStatus = false }
        const searchActive = computed(() => store.state.searchActiveStatus)

        const settingsActiveTab = ref('settings');
        const setSettingsActiveTab = (tab) => {
            switch (tab) {
                case 'settings': settingsActiveTab.value = 'settings'; break;
                case 'data': settingsActiveTab.value = 'data'; break;
                case 'invites': settingsActiveTab.value = 'invites'; break;
                case 'feed': settingsActiveTab.value = 'feed'; break;
                case 'logs': settingsActiveTab.value = 'logs'; break;
                default: '';
            }
        }

        
        const logout = () => {
            store.dispatch('LOGOUT')
        }
        const admin = () => {
            store.dispatch('ADMIN')
        }
        const userEmail = computed(() => {
            return store.state.userEmail
        })
        const accessType = computed(() => {
            return store.state.accessType
        })

        const usersList = computed(() => {
            return store.state.usersList
        })

        const getAccessType = computed(() => {
            return store.state.accessType
        })

        const openWindowEdit = (_id) => {
            store.state.windowEditStatus = true
            store.dispatch('LOAD_ADDRESS_BY_ID', _id)
        }

        const searchSelectAddress = (_id) => {
            searchOutActive()
            openWindowEdit(_id)
            searchInput.value = null
        }

        const searchListComputed = computed(() => {
            return store.state.searchList
        })

        const searchFormDebounce = debounce(function (e) {
            if(e.target.value.length > 0) {
                store.dispatch('SEARCH_FORM', {'query': e.target.value})
            } else {
                store.state.searchList = []
            }
        }, 500)


        /* Статусы в таблице */
        const getStatusClass = (status_id) => {
            switch (status_id) {
                case 0: return 'good'; break;
                case 1: return 'warning'; break;
                case 2: return 'error'; break;
                case 3: return 'disabled'; break;
            }
        }

        const getStatusName = (status_id) => {
            switch (status_id) {
                case 0: return 'Проверен'; break;
                case 1: return 'Уточнить'; break;
                case 2: return 'Ошибка'; break;
                case 3: return 'Отключен'; break;
            }
        }

        /** SETTINGS PROFILE */
        const settingMessageSuccess = ref(null)
        const settingMessageError = ref(null)
        
        const settingForm = reactive({
            id: '',
            name: '',
            surname: '',
            email: '',
            position: '',
            invite: ''
        });

        const settingFormPassword = reactive({
            password: '',
            passwordConfirm: '',
        });

        const btnSettingSaveProfile = () => {
            //save settingForm
            if (settingForm.name.length > 0 && settingForm.surname.length > 0 && settingForm.position.length > 0) {
                settingMessageError.value = ''
                settingMessageSuccess.value = 'Профиль обновлен'

                btnSettingUpdatePassword();
                //
            } else {
                settingMessageError.value = 'Для сохранения заполните все поля'
            }
        }

        const btnSettingUpdatePassword = () => {
            // save Password
            if (settingFormPassword.password.length > 0 && settingFormPassword.passwordConfirm.length > 0) {
                if (settingFormPassword.password == settingFormPassword.passwordConfirm) {
                    // ACTION SAVE
                    settingMessageSuccess.value = 'Установлен новый пароль'
                } else {
                    settingMessageError.value = 'Пароли не совпадают'
                }
            } else {
                settingMessageError.value = '';
            }
        }

        const btnSettingSaveAll = () => {
            btnSettingSaveProfile()
        }

        /** END SETTINGS PROFILE */

        const statusSettingsWindow = ref(false);
        const closeSettings = () => statusSettingsWindow.value = false
        const openSettings = () => statusSettingsWindow.value = true

        const isMobile = () => {
            if (window.innerWidth < 979)
                return true
        }

        const getFeedbackCount = computed(() => {
            return store.state.feedback
        })

        const notifyMessage = computed(() => store.state.notifyMessage);
        const isNotifyMessageError = computed(() => store.state.isNotifyMessageError)
        
        return {
            preloaderStatus,
            windowAddStatus,
            windowEditStatus,
            exportStatus,
            searchInput,
            searchSetActive,
            searchActive,
            searchOutActive,
            admin,
            logout,
            searchFormDebounce,
            searchListComputed,
            searchSelectAddress,
            getStatusClass,
            getStatusName,
            userEmail,
            accessType,
            usersList,
            getAccessType,
            isMobile,
            tabIndexActive,
            setTabIndex,
            getFeedbackCount,
            setSettingsActiveTab,
            settingsActiveTab,
            btnSettingSaveAll,
            settingForm,
            settingFormPassword,
            btnSettingSaveProfile,
            btnSettingUpdatePassword,
            settingMessageError,
            settingMessageSuccess,
            statusSettingsWindow,
            closeSettings,
            openSettings,
            notifyMessage,
            isNotifyMessageError
        }
    },

    components: {
        windowAdd,
        windowEdit,
        salesPoints,
        clientAlarm,
        Export
    }
}
</script>

<style scoped lang="scss">
   * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    }

    @-webkit-keyframes pulse{0%{-webkit-transform:scale(1)}50%{-webkit-transform:scale(1.06)}100%{-webkit-transform:scale(.94)}}.preloader{position:fixed;top:0;left:0;right:0;bottom:0;background-color:#f5f7fa;display:flex;flex-direction:column;justify-content:center;align-items:center;transition:all .5s ease;z-index:7777}.preloader.loaded{opacity:0;visibility:hidden}.preloader.active{opacity:1;visibility:visible}.preloader>.preloader-content{flex:none;height:100px}.preloader>.preloader-content>.logo{transition:all .3s ease;-webkit-animation-name:pulse;-webkit-animation-duration:3s;-webkit-animation-direction:alternate;-webkit-animation-timing-function:ease-in-out;animation-iteration-count:infinite;transition:all .3s ease}.preloader>.preloader-content>.logo>img{width:210px}@media all and (max-width:767px){.preloader>.preloader-content>.logo>img{width:160px}}.preloader>.preloader-content>.label{font-size:14px;font-weight:400;letter-spacing:.3px;margin-top:24px;text-align:center;font-family:'Noto Sans';display:flex;flex-direction:row;align-items:center;justify-content:center;color:#323232;opacity:.7}@media all and (max-width:767px){.preloader>.preloader-content>.label{font-size:13px}}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	/* FADE ANIMATEION */
	.fade-enter-active {
		transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.fade-leave-active {
		transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
        transform: translateY(-20px);
	}

    /* SLIDE FADE ANIMATEION */
    .slide-fade-enter-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-leave-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter-from, .slide-fade-leave-to {
        transform: translateY(10px);
        opacity: 0;
    }

</style>