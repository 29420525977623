<template>
    <div class="section-login">
        <div class="logo"><img src="../assets/img/logo-auth.svg"></div>
        <div class="title">Забыли пароль?</div>
        <div class="form-auth">
            <input type="text" v-model="form.email" placeholder="Email адрес" style="width: 332px;">
            
            <div class="message error" v-if="error_message">{{ error_message }}</div>
            <div class="message success" v-if="success_message">{{ success_message }}</div>
            
            <a href="#auth" class="btn-auth" :class="(form.email && form.password) ? 'active' : ''" @click="reset">Сбросить пароль</a>
            <router-link :to="{'name': 'Reg'}" class="btn-link">Войти в аккаунт</router-link>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex"
import { computed, ref, onMounted, toRaw, reactive} from "vue"

export default {
    name: 'Reset',
    setup() {
        const store = useStore();

        const form = reactive({
            'email': null
        })

        const error_message = computed(() => store.state.reset_error_message)
        const success_message = computed(() => store.state.reset_success_message)

        const reset = () => {
            if (form.email == null) {
                store.state.reset_error_message = 'Заполните email адрес'
            } else {
                store.dispatch('RESET', toRaw(form))
            }
        }

        return {
            form,
            reset,
            error_message,
            success_message
        }
    }
}
</script>