<template>
    <div class="window-excel">
        <div class="window">
            <transition name="fade">
                <div class="select-menu" v-if="statusSelectMenu">
                    <div class="window-select">
                        <div class="title">Выбор {{ selectObj.title }}</div>
                        <div class="search"><input type="text" placeholder="Поиск по названию"></div>
                        <div class="item-list">
                            <div class="item" v-for="(item, index) in selectObj.items" :key="index">
                                <div class="check" v-if="selectObj.type == 'author'" @click="setCheckedObj(selectObj.type, item.email)">
                                    <div class="checkbox" :class="checkedObj[selectObj.type].includes(item.email) ? 'active' : ''"></div>
                                    <span>{{ item.name }}<br>{{ item.email }}</span>
                                </div>

                                <div class="check" v-if="selectObj.type == 'region'" @click="setCheckedObj(selectObj.type, item)">
                                    <div class="checkbox" :class="checkedObj[selectObj.type].includes(item) ? 'active' : ''"></div>
                                    <span>{{ item }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="select-bg" @click="closeSelectMenu"></div>
                </div>
            </transition>
            <transition name="fade">
                <div class="download" v-if="fileActive">
                    <a :href="fileUrl" download="download" target="_blank" @click="clickDownload" class="btn-down"><img src="../assets/img/download-white.svg">Скачать файл</a>
                    <a href="#" @click="close" class="btn-cancel">Закрыть</a>
                </div>
            </transition>
            <div class="close-window" @click="close"><img src="../assets/img/close-window.svg"></div>
            <div class="title">Выгрузка данных</div>
            <div class="content-form">
                <div class="check-group">
                    <div class="check-item">
                        <div class="check" @click="activeCheckbox('address')">
                            <div class="checkbox" :class="checkArr.includes('address') ? 'active' : ''" ></div>
                            <span>Полный адрес</span>
                        </div>
                        <input class="input" v-model="form.address" type="text" placeholder="Содержит текст">
                    </div>
                </div>

                <div class="check-group">
                    <div class="check-item">
                        <div class="check" @click="activeCheckbox('region')">
                            <div class="checkbox" :class="checkArr.includes('region') ? 'active' : ''" ></div>
                            <span>Регион</span>
                        </div>
                        <div class="place-tags" @click="openSelect('region')">
                            <div class="placeholder" v-if="checkedObj['region'].length < 1">Выбрать регион</div>
                            <div class="tag-item" v-for="(tag, index) in checkedObj['region']" :key="index">
                                {{ tag }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="check-group">
                    <div class="check-item">
                        <div class="check" @click="activeCheckbox('agent')">
                            <div class="checkbox" :class="checkArr.includes('agent') ? 'active' : ''" ></div>
                            <span>Агент</span>
                        </div>
                        <input class="input" v-model="form.agent" type="text" placeholder="Содержит текст">
                    </div>
                </div>

                <div class="check-group">
                    <div class="check-item">
                        <div class="check" @click="activeCheckbox('label')">
                            <div class="checkbox" :class="checkArr.includes('label') ? 'active' : ''" ></div>
                            <span>Вывеска</span>
                        </div>
                        <input class="input" v-model="form.label" type="text" placeholder="Содержит текст">
                    </div>
                </div>

                <div class="check-group">
                    <div class="check-item">
                        <div class="check" @click="activeCheckbox('phone')">
                            <div class="checkbox" :class="checkArr.includes('phone') ? 'active' : ''" ></div>
                            <span>Телефон</span>
                        </div>
                        <input class="input" v-model="form.phone" type="text" placeholder="Содержит текст">
                    </div>
                </div>

                <div class="check-group">
                    <div class="check-item">
                        <div class="check" @click="activeCheckbox('comment')">
                            <div class="checkbox" :class="checkArr.includes('comment') ? 'active' : ''" ></div>
                            <span>Комментарий</span>
                        </div>
                        <input class="input" v-model="form.comment" type="text" placeholder="Содержит текст">
                    </div>
                </div>

                <div class="check-group">
                    <div class="check-item">
                        <div class="check" @click="activeCheckbox('notes')">
                            <div class="checkbox" :class="checkArr.includes('notes') ? 'active' : ''"></div>
                            <span>Заметка автора</span>
                        </div>
                        <input class="input" v-model="form.notes" type="text" placeholder="Содержит текст">
                    </div>
                </div>

                <div class="check-group">
                    <div class="check-item">
                        <div class="check" @click="activeCheckbox('userEmail')">
                            <div class="checkbox" :class="checkArr.includes('userEmail') ? 'active' : ''" ></div>
                            <span>Автор</span>
                        </div>
                        <div class="place-tags" @click="openSelect('author')">
                            <div class="placeholder" v-if="checkedObj['author'].length < 1">Выбрать автора</div>
                            <div class="tag-item" v-for="(tag, index) in checkedObj['author']" :key="index">
                                {{ tag }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="btn-group">
                    <a href="#cancel" @click="close" class="btn-cancel">Отмена</a>
                    <a href="#load" class="btn-save" @click="btnExport"><img src="../assets/img/check-white.svg">Выгрузить данные</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex"
import { computed, ref, reactive, toRaw, onBeforeMount} from "vue"
import axios from 'axios'

export default {
    name: 'Export',
    setup() {
        const store = useStore();
        const close = () => {
            store.state.exportStatus = false
        }

        const checkArr = ref([])
        const activeCheckbox = (field) => {
            if (checkArr.value.includes(field)) {
                let index = checkArr.value.indexOf(field)
                checkArr.value.splice(index, 1)
            } else {
                checkArr.value.push(field)
            }
        }

        const statusSelectMenu = ref(0)
        const closeSelectMenu = () => {
            statusSelectMenu.value = 0
        }

        const openSelectMenu = () => {
            statusSelectMenu.value = 1
        }
        
        const cleanArr = ref([])
        const form = reactive({
            address: null,
            region: [],
            agent: null,
            label: null,
            phone: null,
            comment: null,
            notes: null,
            userEmail: []
        })

        const selectObj = reactive({
            title: null,
            items: [],
            type: null
        })

        const checkedObj = reactive({
            author: [],
            region: []
        })

        const setCheckedObj = (type, data) => {
            if (checkedObj[type].indexOf(data) < 0) {
                checkedObj[type].push(data)

                if (type == 'region') {
                     if (!form.region.includes(data))
                        form.region.push(data)
                }

                if (type == 'author') {
                     if (!form.userEmail.includes(data))
                        form.userEmail.push(data)
                }
            } else {
                var index = checkedObj[type].indexOf(data)
                checkedObj[type].splice(index, 1)

                 if (type == 'region') {
                    var ind = form.region.indexOf(data)
                     if (form.region.indexOf(data) > -1)
                        form.region.splice(ind, 1)
                }

                if (type == 'author') {
                    var ind = form.userEmail.indexOf(data)
                     if (form.userEmail.indexOf(data) > -1)
                        form.userEmail.splice(ind, 1)
                }
            }
        }

        const openSelect = (selectType) => {
            switch(selectType) {
                case 'author':
                    selectObj.title = 'авторов';
                    selectObj.items = store.state.usersList;
                    selectObj.type = selectType;
                    openSelectMenu();
                    break;
                case 'region':
                    let regions = []
                    for (let item of store.state.addressList.data) {
                        if (item.region) {
                            if (regions.indexOf(item.region) < 0)
                                regions.push(item.region)
                        }
                    }
                    selectObj.title = 'регионов';
                    selectObj.items = regions;
                    selectObj.type = selectType;
                    openSelectMenu();
                    break;
            }
        }

        const fileActive = ref(false)
        const fileUrl = ref(null)
        const clickDownload = () => {
            close()
        }

        const btnExport = () => {
            let cleanData = {}
            for (let item of Object.keys(form)) {
                if (checkArr.value.includes(item)) {
                    cleanData[item] = form[item] ? form[item] : ''
                    cleanArr.value.push(item)
                }
            }


            let pack = {
                'fields': cleanArr.value,
                'like': cleanData
            }

            axios.post(`${store.state.serverAddress}:3000/api/excel`, toRaw(pack)).then((response) => {
                fileActive.value = true
                fileUrl.value = `${store.state.serverAddress}:3000` + response.data.file
            })
        }

        return {
            close,
            activeCheckbox,
            form,
            btnExport,
            checkArr,
            fileActive,
            fileUrl,
            clickDownload,

            selectObj,
            openSelect,

            checkedObj,
            setCheckedObj,

            statusSelectMenu,
            openSelectMenu,
            closeSelectMenu
        }
    }
}
</script>