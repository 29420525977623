import { createStore } from 'vuex'
import router from '../router'
import axios from 'axios'
import Cookies from 'js-cookie'
export default createStore({
  state: {
    authStatus: false,
    adminStatus: false,
    auth_error_message: null,
    auth_success_message: null,
    reg_success_message: null,
    reg_success_message: null,
    userEmail: null,
    accessType: 0,
    usersList: null,
    preloaderStatus: 0,
    windowCheckListStatus: false,
    checkList: [],
    windowAddStatus: false,
    windowEditStatus: false,
    windowViewStatus: false,
    exportStatus: false,
    statusWindowMobileFilter: false,
    searchActiveStatus: false,
    addressList: null,
    plonqList: null,
    addressListCount: 0,
    editAddressData: null,
    searchList: [],
    activeFeedbackFilter: null,
    activeFilter: null,
    filterGoodCount: 0,
    filterWarningCount: 0,
    filterErrorCount: 0,
    filterDisableCount: 0,
    feedback: {}
  },
  mutations: {
    setAuthStatus (state, payload) {
      state.authStatus = payload
    },

    setAddressList (state, payload) {
      state.addressList = payload
      state.addressListCount = payload.count
    },
    setPlonqList (state, payload){
      state.plonqList = payload
    },

    setEditAddress (state, payload) {
      state.editAddressData = payload
    },
    setplonqAddress (state ,payload){
      state.getplonqid = payload
    },
    setSearchList (state, payload) {
      state.searchList = payload
      
    },

    setfilterGoodCount (state, payload) {
      if (payload == 0) {
        state.filterGoodCount = 0
      } else  
        state.filterGoodCount = state.filterGoodCount + 1
    },

    setfilterWarningCount (state, payload) {
      if (payload == 0)
        state.filterWarningCount = 0
      else  
        state.filterWarningCount = state.filterWarningCount + 1
    },

    setfilterErrorCount (state, payload) {
      if (payload == 0)
        state.filterErrorCount = 0
      else  
        state.filterErrorCount = state.filterErrorCount + 1
    },

    setfilterDisableCount (state, payload) {
      if (payload == 0)
        state.filterDisableCount = 0
      else  
        state.filterDisableCount = state.filterDisableCount + 1
    },

    auth_success_message (state, payload) {
      state.auth_success_message = payload
    },

    auth_error_message (state, payload) {
      state.auth_error_message = payload
    },

    reg_success_message (state, payload) {
      state.reg_success_message = payload
    },

    reg_error_message (state, payload) {
      state.reg_error_message = payload
    },

    setUserEmail (state, payload) {
      state.userEmail = payload
    },

    setAccessType (state, payload) {
      state.accessType = payload
    },

    setUsersList (state, payload) {
      state.usersList = payload
    },

    setFeedback (state, payload) {
      state.feedback = payload
    },

    setPreloaderStatusPlus (state) {
      state.preloaderStatus++
    },

    setPreloaderStatusMinus (state) {
      state.preloaderStatus--
    }

  },
  actions: {
    async ADMIN (context){
      context.commit('setAuthStatus', true)
      router.push({'name': 'Admin'})
    },
    async INIT (context) {
      context.commit('setPreloaderStatusPlus', true)
      let cookieAuthStatus = Cookies.get('authStatus')
      let token = Cookies.get('token')
      let userEmail = Cookies.get('email')
      let accessType = Cookies.get('accessType')

      if (cookieAuthStatus) {
        axios.defaults.headers.common['authorization'] = token;

        context.commit('setAuthStatus', true)
        context.commit('setUserEmail', userEmail)
        context.commit('setAccessType', accessType)
        // router.push({'name': 'Main'})


        await context.dispatch('getAddress')
        await context.dispatch('getFeedback')
        await context.dispatch('getPlonq')
        context.commit('setPreloaderStatusMinus', true)
      } else {
        context.commit('setPreloaderStatusMinus', true)
      }
    },

    async LOAD_ADDRESS_BY_ID (context, _id) {
      return await axios.get(process.env.VUE_APP_SERVER + `/api/address/${_id}`).then((response) => {
        context.commit('setEditAddress', response.data)
        return response.data
      })
    },
    async LOAD_PLONQ_BY_ID (context, _id) {
      return await axios.get(process.env.VUE_APP_SERVER + `/api/plonq/${_id}`).then((response) => {
        context.commit('setplonqAddress', response.data)
        return response.data
  
      })
    },
    async SEARCH_FORM (context, payload) {
      return await axios.post(process.env.VUE_APP_SERVER + '/api/search', payload).then((response) => {
        context.commit('setSearchList', response.data.data)
        return response.data.data
      })
    },

    async getFeedback (context) {
      return await axios.get(process.env.VUE_APP_SERVER + '/api/status-list').then(async (response_status) => {
        let rf = await axios.get(process.env.VUE_APP_SERVER + '/api/feedback').then((response_feedback) => {
          return response_feedback
        })

        let statusListCount = {}
        for (let item of response_status['data']['data']) {
          statusListCount[item.id] = 0
          for (let feedItem of rf['data']['data']) {
            if (feedItem['statusIds'].includes(item.id)) {
              statusListCount[item.id]++
            }
          }
        }

        let object_data = {
          'statusList': response_status['data']['data'],
          'feedbackList': rf['data'],
          'statusListCount': statusListCount
        }
        context.commit('setFeedback', object_data)
        return response_status.data
      })
    },

    async getAddress (context) {
      context.commit('setfilterGoodCount', 0)
      context.commit('setfilterWarningCount', 0)
      context.commit('setfilterErrorCount', 0)
      context.commit('setfilterDisableCount', 0)
      let email = context.state.userEmail

      return await axios.get(process.env.VUE_APP_SERVER + '/api/address',  {
        params: {
          email: email
      },

      }).then((response) => {
        for (let item of response.data.data) {
          switch (item.status_id) {
            case 0: context.commit('setfilterGoodCount', 1); break;
            case 1: context.commit('setfilterWarningCount', 1); break;
            case 2: context.commit('setfilterErrorCount', 1); break;
            case 3: context.commit('setfilterDisableCount', 1); break;
          }
        }

        context.commit('setAddressList', response.data)
        return response.data
      })
    },
    async getPlonq (context) {
      return await axios.get(process.env.VUE_APP_SERVER + '/api/plonq').then((response) => {
        context.commit('setPlonqList', response.data)
        return response.data
      })
    },
    async LOAD_USERS (context) {
      return await axios.get(process.env.VUE_APP_SERVER + '/api/users').then((response) => {
        context.commit('setUsersList', response.data)
        return response.data
      })
    },
    async LOGIN (context, payload) {
      let sendData = {
        'email': payload.email.toLowerCase(),
        'password': payload.password
      }

      return await axios.post(process.env.VUE_APP_SERVER + '/api/auth/login', sendData).then((response) => {
        if (response.data.result) {
          context.commit('auth_success_message', 'Успешная авторизация')
          context.commit('auth_error_message', null)
          setTimeout(() => {context.commit('auth_success_message', null)}, 500)
          context.commit('setAuthStatus', true)
          context.commit('setUserEmail', response.data.user.email)
          Cookies.set('authStatus', 'true', { expires: 7 })
          Cookies.set('token', response.data.token, { expires: 7 })
          Cookies.set('email', response.data.user.email, { expires: 7 })
          Cookies.set('accessType', response.data.user.accessType, { expires: 7 })
          context.dispatch('INIT')
          router.push({'name': 'Main'})
        } else {
          context.commit('auth_error_message', response.data.error)
          context.commit('auth_success_message', null)
        }
        return response.data
      })
      
    },

    async REG (context, payload) {
      let sendData = {
        'name': payload.name,
        'email': payload.email.toLowerCase(),
        'password': payload.password,
        'code': payload.code
      }

      return await axios.post(process.env.VUE_APP_SERVER + '/api/auth/reg', sendData).then((response) => {
        if (response.data.result) {
          context.commit('auth_success_message', 'Успешная регистрация')
          context.commit('auth_error_message', null)

          setTimeout(() => {
            context.commit('auth_success_message', null)
          }, 500)

          context.commit('setAuthStatus', true)
          context.commit('setUserEmail', response.data.email)
          
          Cookies.set('authStatus', 'true', { expires: 7 })
          Cookies.set('token', response.data.token, { expires: 7 })
          Cookies.set('email', response.data.email, { expires: 7 })
          Cookies.set('accessType', response.data.accessType, { expires: 7 })

          context.dispatch('INIT')

          router.push({'name': 'Auth'})
        } else {
          context.commit('reg_error_message', response.data.error)
          context.commit('reg_success_message', null)
        }

        return response.data
      })
      
    },

    async LOGOUT (context) {
      Cookies.remove('authStatus')
      Cookies.remove('token')
      Cookies.remove('email')
      context.commit('setAuthStatus', false)
      router.push({'name': 'Auth'})
    }
  },
  modules: {
  }
})
