import { createRouter, createWebHistory } from 'vue-router'
import store from "../store/index"

import Main from "../views/Main.vue"
import Admin from "../views/Admin.vue"
import Auth from "../views/Auth.vue"
import Reg from "../views/Reg.vue"
import Reset from "../views/Reset.vue"
import Map from "../views/Map.vue"

const routes = [
  { path: "/", component: Main, name: "Main", meta: { protect: true } },
  { path: "/admin", component: Admin, name: "Admin", 
  //  meta: { protect: true, admin: true }
},
  { path: "/auth", component: Auth, name: "Auth" },
  { path: "/reg", component: Reg, name: "Reg" },
  { path: "/reset", component: Reset, name: "Reset" },
  { path: "/map", component: Map, name: "Map"}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.protect && !store.state.authStatus) next({ name: 'Auth' })
  else next()
})
router.beforeEach((to, from, next) => {
  if(to.meta.protect) store.state.accessType == '2' ? next({name: 'Admin'}) : next()
  if (to.meta.protect && !store.state.authStatus) next({ name: 'Auth' })
  next()
})
export default router
