<template>
    <div class="btn-panel-mobile" v-if="isMobile()">
        <a href="#" class="btn-add" @click="openWindowAdd"><img src="../assets/img/add.svg">Добавить адрес</a>
        <a href="#" class="btn-export" @click="openWindowAdd"><img src="../assets/img/download.svg">Выгрузка</a>

        <div class="panel-group">
            <a href="http://map.sponq.ru" target="_blank" class="label-stats">
                <img src="../assets/img/pin-gold.svg">
                <span>Адресов</span>
                <span class="stats-count">{{ addressListCount }}</span>
            </a>

            <div class="btn-filter" @click="setStatusWindowMobileFilter(true)"><img src="../assets/img/filter.svg"></div>

            <transition name="fade">
                <div class="filter-window" v-if="statusWindowMobileFilter">
                    <div class="filter-window">
                        <div class="title">Фильтр по статусу</div>

                        <div class="btn-filter-list">
                            <a href="#filter-all" @click="setFilter(null); setStatusWindowMobileFilter(false)" class="btn-filter th-all">
                                <span>Все адреса</span>
                            </a>

                            <a href="#filter-green" @click="setFilter(0); setStatusWindowMobileFilter(false)" class="btn-filter th-green">
                                <div class="span-group">
                                    <img src="../assets/img/check-green.svg">
                                    <span>Проверен</span>
                                </div>
                                <div class="label-count"><span>{{ getfilterGoodCount }}</span></div>
                            </a>

                            <a href="#filter-blue" @click="setFilter(1); setStatusWindowMobileFilter(false)" class="btn-filter th-blue">
                                <div class="span-group">
                                    <img src="../assets/img/pen-blue.svg">
                                    <span>Уточнения</span>
                                </div>
                                <div class="label-count"><span>{{ getfilterWarningCount }}</span></div>
                            </a>

                            <a href="#filter-red" @click="setFilter(2); setStatusWindowMobileFilter(false)" class="btn-filter th-red">
                                <div class="span-group">
                                    <img src="../assets/img/close-red.svg">
                                    <span>Ошибки</span>
                                </div>
                                <div class="label-count"><span>{{ getfilterErrorCount }}</span></div>
                            </a>

                            <a href="#filter-gray" @click="setFilter(3); setStatusWindowMobileFilter(false)" class="btn-filter th-gray">
                                <div class="span-group">
                                    <img src="../assets/img/off-gray.svg">
                                    <span>Отключен</span>
                                </div>
                                <div class="label-count"><span>{{ getfilterDisableCount }}</span></div>
                            </a>
                        </div>
                    </div>
                    <div class="filter-bg" @click="setStatusWindowMobileFilter(false)"></div>
                </div>
            </transition>
        </div>
    </div>

    <div class="btn-panel" v-if="!isMobile()">
        <a href="#" class="btn-add" @click="openWindowAdd"><img src="../assets/img/add.svg">Добавить адрес</a>
        <a href="#" class="btn-export" @click="openWindowExport"><img src="../assets/img/download.svg">Выгрузка</a>

        <div class="btn-filter-group">
            <a href="#filter-all" @click="setFilter(null)" class="btn-filter th-all">
                <span>Все адреса</span>
            </a>

            <a href="#filter-green" @click="setFilter(0)" class="btn-filter th-green">
                <img src="../assets/img/check-green.svg">
                <span>Проверен</span>
                <div class="label-count"><span>{{ getfilterGoodCount }}</span></div>
            </a>

            <a href="#filter-blue" @click="setFilter(1)" class="btn-filter th-blue">
                <img src="../assets/img/pen-blue.svg">
                <span>Уточнения</span>
                <div class="label-count"><span>{{ getfilterWarningCount }}</span></div>
            </a>

            <a href="#filter-red" @click="setFilter(2)" class="btn-filter th-red">
                <img src="../assets/img/close-red.svg">
                <span>Ошибки</span>
                <div class="label-count"><span>{{ getfilterErrorCount }}</span></div>
            </a>

            <a href="#filter-gray" @click="setFilter(3)" class="btn-filter th-gray">
                <img src="../assets/img/off-gray.svg">
                <span>Отключен</span>
                <div class="label-count"><span>{{ getfilterDisableCount }}</span></div>
            </a>
        </div>

        <a href="http://map.sponq.ru" target="_blank" class="label-stats">
            <img src="../assets/img/pin-gold.svg">
            <span>Адресов</span>
            <span class="stats-count">{{ addressListCount }}</span>
        </a>
    </div>
    <div class="content" :class="isMobile() ? 'mobile': ''">
        <div class="content-card" v-if="isMobile()">
            <div class="card-list" v-if="getAddress.length > 0">
                <div class="card" v-for="itemAddress in getAddress" :key="itemAddress.id">
                    <div class="card-header">
                        <div class="label">{{ itemAddress.label || itemAddress.agent }}</div>
                    </div>
                    <div class="title">{{ itemAddress.address }}</div>
                        
                    <div class="author">{{ itemAddress.userEmail }}</div>
                    <div class="card-footer">
                        <div class="status">
                            <div class="label" :class="getStatusClass(itemAddress.status_id)">{{ getStatusName(itemAddress.status_id) }}</div>
                        </div>
                        <div class="edit">
                            <div class="btn-edit" @click="openWindowEdit(itemAddress._id)">Редактировать</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-nothing" v-else>
                Ничего не найдено
            </div>
    </div>

        <div class="content-table" v-if="!isMobile()">
            <table>
                <thead>
                    <tr>
                        <td>№</td>
                        <td>Дата</td>
                        <td>Название</td>
                        <td class="address-width">Адрес</td>
                        <td>
                            <span><img src="../assets/img/settings.svg" @click="windowCheckListOpen" v-if="getAccessType == 1"> Создал</span>
                            <transition-group name="fade">
                                <div class="user-list" v-if="windowCheckListStatus">
                                    <div class="title">Фильтр по аккаунтам</div>
                                    <div class="item-list" v-for="(userItem, index) in usersList" @click="toggleCheck(userItem.email)" :key="index">
                                        <div class="check" :class="checkCheck(userItem.email) ? 'active': ''"></div>
                                        <div class="user-info">{{ userItem.email }}<span>{{ userItem.name }}</span></div>
                                    </div>
                                    <!--<a href="javascript://" class="btn-confirm" @click="checkConfirm">Применить</a>-->
                                </div>
                                <div class="user-bg" @click="windowCheckListClose" v-if="windowCheckListStatus"></div>
                            </transition-group>
                        </td>
                        <td>Статус</td>
                        <td><img src="../assets/img/pen-gray.svg"></td>
                    </tr>
                </thead>
                <tbody v-if="getAddress.length > 0">
                    <tr v-for="(itemAddress, index) in getAddress" :key="itemAddress.id">
                        <td>#{{ index + 1 }}</td>
                        <td>{{ itemAddress.created_at }}</td>
                        <td>{{ itemAddress.label || itemAddress.agent }}</td>
                        <td>{{ itemAddress.address }}</td>
                        <td>{{ itemAddress.userEmail }}</td>
                        <td><div class="label" :class="getStatusClass(itemAddress.status_id)">{{ getStatusName(itemAddress.status_id) }}</div></td>
                        <td><div class="table-btn-edit" @click="openWindowEdit(itemAddress._id)"><img src="../assets/img/pen-indigo.svg"></div></td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="7">Адреса не найдены</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex"
import { computed } from "vue"

export default {
    name: 'salesPoints',
    setup() {
        const store = useStore();

        const openWindowAdd = () => {
            store.state.windowAddStatus = true
        }

        const openWindowEdit = (_id) => {
            if (userEmail) {
                
            }
            store.state.windowEditStatus = true
            store.dispatch('LOAD_ADDRESS_BY_ID', _id)
        }
        
        const openWindowExport = () => {
            store.state.exportStatus = true
        }

        const userEmail = computed(() => {
            return store.state.userEmail
        })

        const usersList = computed(() => {
            return store.state.usersList
        })

        const getAccessType = computed(() => {
            console.log(store.state.accessType)
            return store.state.accessType
        })

        /* user check menu */
        const windowCheckListStatus = computed(() => store.state.windowCheckListStatus)
        const windowCheckListOpen = () => { store.state.windowCheckListStatus = true }
        const windowCheckListClose = () => { store.state.windowCheckListStatus = false }

        const toggleCheck = (email) => {
            let items = store.state.checkList
            if (items.indexOf(email) > -1) {
                const index = items.indexOf(email);
                items.splice(index, 1);
            } else {
                store.state.checkList.push(email)
            }
        }

        const checkCheck = (email) => {
            let items = store.state.checkList
            if (items.indexOf(email) > -1)
                return true
            else
                return false
        }

        const checkConfirm = () => {
            windowCheckListClose()
        }

        /* FILTER */
        const activeFilterId = computed(() => store.state.activeFilter)
        const setFilter = (status_id) => {
            store.state.activeFilter = status_id
        }

        const getAddress = computed(() => {
            let addressList = store.state.addressList
            let checkList = store.state.checkList
            if (addressList != undefined) {
                //addressList.data.slice(0, 100)
                var addressListFiltered = addressList.data.filter((item) => {
                    if (activeFilterId.value == null && checkList.length < 1) {
                        return item
                    }
                    if (activeFilterId.value == null && checkList.length > 0) {
                        if (checkList.indexOf(item.userEmail) > -1)
                            return item
                    }
                    if (activeFilterId.value != null && checkList.length < 1) {
                        if (activeFilterId.value == item.status_id) {
                            return item
                        }
                    }
                    if (activeFilterId.value != null && checkList.length > 0) {
                        if (activeFilterId.value == item.status_id && checkList.indexOf(item.userEmail) > -1) {
                            return item
                        }
                    }
                })

                return addressListFiltered.slice(0, 500)
            } else {
                return {
                    'data': null,
                    'filter': null,
                    'count': 0
                }
            }
        })


        const addressListCount = computed(() => store.state.addressListCount)


        /* Статусы в таблице */
        const getStatusClass = (status_id) => {
            switch (status_id) {
                case 0: return 'good'; break;
                case 1: return 'warning'; break;
                case 2: return 'error'; break;
                case 3: return 'disabled'; break;
            }
        }

        const getStatusName = (status_id) => {
            switch (status_id) {
                case 0: return 'Проверен'; break;
                case 1: return 'Уточнить'; break;
                case 2: return 'Ошибка'; break;
                case 3: return 'Отключен'; break;
            }
        }

        const getfilterGoodCount = computed(() => store.state.filterGoodCount)
        const getfilterWarningCount = computed(() => store.state.filterWarningCount)
        const getfilterErrorCount = computed(() => store.state.filterErrorCount)
        const getfilterDisableCount = computed(() => store.state.filterDisableCount)

        const statusWindowMobileFilter = computed(() => store.state.statusWindowMobileFilter)
        const setStatusWindowMobileFilter = (status) => {
            store.state.statusWindowMobileFilter = status
        }

        const isMobile = () => {
            if (window.innerWidth < 979)
                return true
        }

        return {
            openWindowAdd,
            openWindowEdit,
            openWindowExport,
            getAddress,
            addressListCount,
            getStatusClass,
            getStatusName,
            activeFilterId,
            setFilter,
            getfilterGoodCount,
            getfilterWarningCount,
            getfilterErrorCount,
            getfilterDisableCount,
            userEmail,
            usersList,
            getAccessType,
            toggleCheck,
            checkCheck,
            windowCheckListStatus,
            windowCheckListOpen,
            windowCheckListClose,
            statusWindowMobileFilter,
            setStatusWindowMobileFilter,
            isMobile
        }
    }
}
</script>

<style>
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	/* FADE ANIMATEION */
	.fade-enter-active {
		transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.fade-leave-active {
		transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
        transform: translateY(-20px);
	}

    /* SLIDE FADE ANIMATEION */
    .slide-fade-enter-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-leave-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter-from, .slide-fade-leave-to {
        transform: translateY(10px);
        opacity: 0;
    }

</style>