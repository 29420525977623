<template>
    <div class="btn-panel" v-if="!isMobile()">
        <div class="btn-filter-group" style="margin-left: 0px;">
            <a href="#filter-all" @click="setFilter(null);" class="btn-filter th-all">
                <span>Все замечания</span>
            </a>

            <a href="#filter-all" 
                @click="setFilter(statusItem['id'])"
                class="btn-filter padding-15" 
                :class="getStatusListCount[statusItem['id']] > 0 ? 'th-green' : 'th-gray'"
                v-for="(statusItem, index) in getStatusList" 
                :key="index"
            >
                <span>{{ statusItem['name'] }} </span>
                <div class="label-count"><span>{{ getStatusListCount[statusItem['id']] }}</span></div>
            </a>
        </div>
    </div>
    <div class="content" :class="isMobile() ? 'mobile': ''">
        <div class="content-card" v-if="isMobile()">
            <div class="card-list" v-if="Object.keys(getFeedback).length > 0">
                <div class="card" v-for="itemAddress in getFeedback" :key="itemAddress.id">
                    <div class="card-header">
                        <div class="label">{{ itemAddress.label || itemAddress.agent }}</div>
                    </div>
                    <div class="title">{{ itemAddress.content }}</div>
                        
                    <div class="tag-list">
                        <span class="status-tag" v-for="status_name in itemAddress['statusTags']" :key="status_name">
                            {{ status_name }}
                        </span>
                    </div>

                    <div class="card-footer">
                        <div class="date">
                            {{ itemAddress.createdAt }}
                        </div>
                        <div class="edit">
                            <div class="btn-edit" @click="openWindowEdit(itemAddress.id)">Редактировать</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-nothing" v-else>
                Ничего не найдено
            </div>
        </div>

        <div class="content-table" v-if="!isMobile()">
            <table>
                <thead>
                    <tr>
                        <td>№</td>
                        <td>Дата</td>
                        <td class="address-width">Адрес</td>
                        <td class="address-width">Заметка</td>
                        <td>Замечания</td>
                        <td>Создал</td>
                        <td><img src="../assets/img/pen-gray.svg"></td>
                        <td><img src="../assets/img/close-window.svg"></td>
                    </tr>
                </thead>
                <tbody v-if="Object.keys(getFeedback).length > 0">
                    <tr v-for="(itemAddress, index) in getFeedback" :key="itemAddress.length">
                        <td>#{{ index + 1 }}</td>
                        <td>{{ itemAddress.created_at }}</td>
                        <td>{{ itemAddress.address }}</td>
                        <td>{{ itemAddress.content || '-' }}</td>
                        <td style="width: 500px; height: auto;">
                            <div class="tag-list">
                                <span class="status-tag">
                                    Нет магазина
                                </span>
                            </div>
                        </td>
                        <td>{{ itemAddress.userEmail || '-' }}</td>
                        <td style="width: 40px;"><div class="table-btn-edit" @click="openWindowEdit(itemAddress.address_id)"><img src="../assets/img/pen-indigo.svg"></div></td>
                        <td style="width: 40px;"><div class="table-btn-remove" @click="removeAlarmBtn(itemAddress._id)"><img src="../assets/img/close-red.svg"></div></td>
                    </tr>
                </tbody>
                    <!-- <tr>
                        <td colspan="7">Нет новых замечаний</td>
                    </tr> -->
            </table>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex"
import {defineComponent, computed, ref, onBeforeMount, toRaw, reactive} from "vue"

import { debounce } from 'debounce'
import axios from 'axios'
import Swal from 'sweetalert2'

export default  defineComponent({
    name: 'clientAlarm',
    setup() {

        const store = useStore();

        const windowAddStatus = computed(() => store.state.windowAddStatus)
        const windowEditStatus = computed(() => store.state.windowEditStatus)

        const openWindowAdd = () => {
            store.state.windowAddStatus = true
        }

        const openWindowEdit = (id) => {
            store.state.windowEditStatus = true
            store.dispatch('LOAD_ADDRESS_BY_ID', id)
        }

        const removeAlarm = (id) => {
            console.log(id)
            axios.delete( process.env.VUE_APP_SERVER + `/api/client_alarm/` + id).then((response) => {
                console.log('remove feedback axios')
            })
        }

        const removeAlarmBtn = (id) => {
            Swal.fire({
                title: 'Удаляем замечание?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Отмена',
                confirmButtonText: 'Да, удалить'
                }).then((result) => {
                if (result.isConfirmed) {
                    removeAlarm(id)

                    Swal.fire(
                    'Замечание удалено',
                    '',
                    'success'
                    )
                }
            })
        }
        
        const userEmail = computed(() => {
            return store.state.userEmail
        })

        const usersList = computed(() => {
            return store.state.usersList
        })

        const getAccessType = computed(() => {
            console.log(store.state.accessType)
            return store.state.accessType
        })

        /* user check menu */
        const windowCheckListStatus = computed(() => store.state.windowCheckListStatus)
        const windowCheckListOpen = () => { store.state.windowCheckListStatus = true }
        const windowCheckListClose = () => { store.state.windowCheckListStatus = false }

        const toggleCheck = (email) => {
            let items = store.state.checkList
            if (items.indexOf(email) > -1) {
                const index = items.indexOf(email);
                items.splice(index, 1);
            } else {
                store.state.checkList.push(email)
            }
        }

        const checkCheck = (email) => {
            let items = store.state.checkList
            if (items.indexOf(email) > -1)
                return true
            else
                return false
        }

        const checkConfirm = () => {
            windowCheckListClose()
        }

        /* FILTER */
        const activeFeedbackFilterId = computed(() => store.state.activeFeedbackFilter)
        const setFilter = (status_id) => {
            store.state.activeFeedbackFilter = status_id
        }

        const getStatusList = computed(() => {
            return store.state.feedback['statusList']
        })

        const getStatusListCount = computed(() => {
            return store.state.feedback['statusListCount']
        })

        // const getFeedback = computed(() => {
        //     let feedback = store.state.feedback

        //     if (Object.keys(feedback).length > 0) {
        //         //addressList.data.slice(0, 100)

        //         var feedbackListFiltered = feedback['feedbackList'].data.filter((item) => {
        //             if (activeFeedbackFilterId.value == null) {
        //                 if (item['statusIds'].length > 0) {
        //                     let ids = JSON.parse(item['statusIds'])
        //                     item['statusTags'] = []
        //                     for(let status of feedback['statusList']) {
        //                         if (ids.includes(status.id)) {
        //                             item['statusTags'].push(status.name)
        //                         }
        //                     }
        //                 }

        //                 return item
        //             }

        //             if (activeFeedbackFilterId.value != null) {
        //                 if (item['statusIds'].includes(activeFeedbackFilterId.value)) {
        //                     return item
        //                 }
        //             }

        //         })

        //         return feedbackListFiltered.slice(0, 500)
        //     } else {
        //         return {
        //             'data': null,
        //             'filter': null,
        //             'count': 0
        //         }
        //     }
        // })
        const getFeedback = ref("address")
        const getAllFeedback = async() =>{
            try {
                let res = await axios({
                    url: process.env.VUE_APP_SERVER + '/api/client_alarm',
                    method: 'get',
                    timeout: 8000,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                console.log(res.data.data)
                if(res.status == 200){
                    const allAddress = res.data.data
                    await allAddress.forEach(async(item) => {
                        let id = item.address_id
                        let address = await axios({
                            url: process.env.VUE_APP_SERVER + `/api/address/${id}`,
                            method: 'get',
                            timeout: 8000,
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        })
                        item.address = address.data.response.address
                    })
                    setTimeout(() => {
                        getFeedback.value = allAddress
                        console.log(getFeedback.value)
                    }, 500);
                
                }    
            }
            catch (err) {
                console.error(err);
                    }
        }
        onBeforeMount(async () => {
            await getAllFeedback()
        })
        
        const addressListCount = computed(() => store.state.addressListCount)


        const isMobile = () => {
            if (window.innerWidth < 979)
                return true
        }

        return {
            windowAddStatus,
            windowEditStatus,

            openWindowAdd,
            openWindowEdit,

            getFeedback,
            getAllFeedback,
            getStatusList,
            getStatusListCount,
            removeAlarmBtn,

            addressListCount,

            activeFeedbackFilterId,
            setFilter,

            userEmail,
            usersList,
            getAccessType,

            isMobile
        }
    }
})
</script>

<style>
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	/* FADE ANIMATEION */
	.fade-enter-active {
		transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.fade-leave-active {
		transition: all 0.1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
        transform: translateY(-20px);
	}

    /* SLIDE FADE ANIMATEION */
    .slide-fade-enter-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-leave-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter-from, .slide-fade-leave-to {
        transform: translateY(10px);
        opacity: 0;
    }

</style>