<template>
  <div class="hello">
    <h1>{{ msg }}</h1>

    <div id="myMap"></div>
  </div>
</template>

<script>

export default {
  
  name: 'hello',
  data () {
    return {
      
      msg: 'Welcome to Your Vue.js App',
      markers: [
  { lat: -31.56391, lng: 147.154312 },
  { lat: -33.718234, lng: 150.363181 },
  { lat: -33.727111, lng: 150.371124 },
  { lat: -33.848588, lng: 151.209834 },
  { lat: -33.851702, lng: 151.216968 },
      ],
    }},
  mounted: async function() {
        let pos = {lat: 51.093048, lng: 6.84212,}
        var MY_MAPTYPE_ID = 'a35b41f7f2d8b928';
        let opt = {
            center: pos,
            zoom: 15,
            mapId: MY_MAPTYPE_ID
        }
    const map = new google.maps.Map(document.getElementById('myMap'),opt)
    const infoWindow = new google.maps.InfoWindow({
    content: "",
    disableAutoPan: true,
  });
  // Create an array of alphabetical characters used to label the markers.
  const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  // Add some markers to the map.
  const markers = this.markers.map((position, i) => {
    const label = labels[i % labels.length];
    const marker = new google.maps.Marker({
      position,
      label,
    });

    // markers can only be keyboard focusable when they have click listeners
    // open info window when marker is clicked
    marker.addListener("click", () => {
      infoWindow.setContent(label);
      infoWindow.open(map, marker);
    });
    return marker;
  });

  // Add a marker clusterer to manage the markers.
  new markerClusterer.MarkerClusterer({ map, markers });

  }

}
</script>
<style scoped>
    #myMap {
    height:600px;
    width: 100%;
   }
</style>