<template>
  <div class="file-upload">
    <input type="file" accept=".xlsx" @change="onFileChange" />
    <div v-if="progress" class="progess-bar" :style="{'width': progress}">
       {{progress}}
    </div>
    <button @click="onUploadFile" class="upload-button"
    :disabled="!this.selectedFile">Upload file</button>
  </div>


</template>



<script>
import axios from "axios";

export default {
  data() {
    return {
      selectedFile: "",
      progress: 0,
      databuffer: [0,1,2,3],
      databufferkey: [],
      allData: [],
      selectedCode: '',
      selectedElement: []
    };
  },
  methods: {
    onFileChange(e) {
      const selectedFile = e.target.files[0];
      this.selectedFile = selectedFile;
      this.progress = 0;
    },

    async onUploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file

      await axios
        .post(process.env.VUE_APP_SERVER + "/api/Excel", formData, {
          onUploadProgress: ProgressEvent => {
            let progress =
              Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
              +"%";
            this.progress = progress;
          }
        })
        .then(async res => {
          function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
          }
          await sleep(1000);

          var link = document.createElement("a")
          let name = Date.now() + '.xlsx'
          name = name[name?.length-1]
          link.setAttribute('download', name)
          link.href = process.env.VUE_APP_SERVER + res['data']['file']
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style scoped>
.file-upload {
  box-shadow: 2px 2px 9px 2px #ccc;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  width: 60%;
  margin: 0 auto;
}
.file-upload_2 {
  box-shadow: 2px 2px 9px 2px #ccc;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1rem;
  width: 60%;

  margin: 0 auto;
}

input {
  font-size: 0.9rem;
}

input,
div,
button {
  margin-top: 2rem;
}

.upload-button {
  width: 7rem;
  padding: 0.5rem;
  background-color: #278be9;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 1rem;
}

.upload-button:disabled {
  background-color: #b3bcc4;
  cursor: no-drop;
}
</style>