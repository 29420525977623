<template>
    <div class="window-add">
        <div class="window">
            <div class="close-window" @click="close"><img src="../assets/img/close-window.svg"></div>
            <div class="title">Добавить адрес</div>
            <div class="window-content">
                <div class="window-tab">
                    <div class="tab-item green" :class="tabs.id == 0 ? 'active' : ''" @click="setTab(0)">Данные</div>
                    <div class="tab-item" :class="tabs.id == 1 ? 'active' : ''" @click="setTab(1)">Карта</div>
                </div>
                <div class="content-form" v-if="(isMobile() && tabs.id == 0) || !isMobile()">
                    <div class="input-block" style="margin-bottom: 22px;">
                        <div class="label">Адрес</div>
                        <div class="input icon">
                            <img src="../assets/img/search-gold.svg">
                            <input type="text" 
                                v-model="form.address" 
                                
                                @input="searchSuggest"
                                placeholder="Введите адрес"
                            >
                        </div>

                        <div class="address-suggest" v-if="suggestList">
                            <div class="close-suggest" @click="suggestList = null"><img src="../assets/img/close-window.svg"></div>
                            <div class="item-suggest" 
                                v-for="suggest in suggestList"
                                @click="setAddress(suggest)"
                                @blur="suggestList = null"
                                :key="suggest"
                            >{{ suggest.unrestricted_value }}</div>
                        </div>
                    </div>

                    <div class="input-block">
                        <div class="label">Полный адрес</div>
                        <div class="input">
                            <input type="text" v-model="form.country" spellcheck="false" placeholder="Страна">
                        </div>
                    </div>

                    <div class="input-group">
                        <input type="text" v-model="form.region" spellcheck="false" placeholder="Облать">
                        <input type="text" v-model="form.city" spellcheck="false" placeholder="Город / Поселение">
                    </div>

                    <div class="input-group">
                        <input type="text" v-model="form.index" spellcheck="false" placeholder="Индекс">
                        <input type="text" v-model="form.street" spellcheck="false" placeholder="Улица">
                        <input type="text" v-model="form.building" spellcheck="false" placeholder="Номер дома">
                    </div>

                    <div class="input-group">
                        <input type="text" v-model="form.coords" @input="setHandCoords" placeholder="Координаты">
                        <input type="text" v-model="form.statusAddress" disabled placeholder="Статус адреса">
                    </div>

                    <div class="input-group" style="margin-top: 20px;">
                        <input type="text" v-model="form.label" spellcheck="false" placeholder="Название точки">
                        <input type="text" v-model="form.agent" spellcheck="false" placeholder="ООО / ИП Иванов И.И.">
                    </div>

                    <div class="input-block">
                        <div class="input icon">
                            <img src="../assets/img/phone-input.svg">
                            <input type="text" v-model="form.phone" spellcheck="false" placeholder="Номер телефона">
                        </div>
                    </div>

                    <!--<div class="label-title" style="margin-top: 15px;">График работы</div>
                    <div class="work-block">
                        <div class="work-item" :class="workTab == 0 ? 'active' : ''" @click="setWork(0)">По рассписанию</div>
                        <div class="work-item" :class="workTab == 1 ? 'active' : ''" @click="setWork(1)">Круглосуточно</div>
                        <div class="work-item" :class="workTab == 2 ? 'active' : ''" @click="setWork(2)">Не известно</div>
                    </div>-->

                    <div class="work-block-input" :class="workTab == 0 ? 'active' : ''">
                        <div class="work-item-input-group">
                            <div class="input-group-label">Пн-Пт</div>
                            <input type="text" id="work-pn-pt" placeholder="10:00 - 21:00">
                        </div>

                        <div class="work-item-input-group">
                            <div class="input-group-label">Сб-Вс</div>
                            <input type="text" id="work-sb-vs" placeholder="10:00 - 21:00">
                        </div>
                    </div>

                    <div class="input-block" style="margin-top: 20px;">
                        <div class="input icon">
                            <img src="../assets/img/pen-input.svg">
                            <input type="text" v-model="form.comment" spellcheck="false" placeholder="Комментарий к точке продаж">
                        </div>
                    </div>

                    <div class="input-block">
                        <div class="input icon">
                            <img src="../assets/img/pen-input.svg">
                            <input type="text" v-model="form.notes" spellcheck="false" placeholder="Заметки">
                        </div>
                    </div>

                    <div class="input-group">
                        <input type="text" v-model="form.company" spellcheck="false" placeholder="Дистрибьютор">
                        <input type="text" v-model="form.division" spellcheck="false" placeholder="Дивизион">
                        <input type="text" v-model="form.code" spellcheck="false" placeholder="Код адреса">
                    </div>

                    <div class="status-block">
                        <div class="status-item green" :class="form.status_id == 0 ? 'active' : ''" @click="setStatus(0)">Впорядке</div>
                        <div class="status-item blue" :class="form.status_id == 1 ? 'active' : ''" @click="setStatus(1)">Уточнить</div>
                        <div class="status-item red" :class="form.status_id == 2 ? 'active' : ''" @click="setStatus(2)">Ошибка</div>
                        <div class="status-item gray" :class="form.status_id == 3 ? 'active' : ''" @click="setStatus(3)">Отключен</div>
                    </div>
                    <!-- <div class="status-block">
                        <div class="status-item green" :class="form.plonq == 0 ? 'active' : ''" @click="plonq(0)">PlonqX</div>
                        <div class="status-item green" :class="form.plonq == 1 ? 'active' : ''" @click="plonq(1)">PlonqX</div>
                        <div class="status-item green" :class="form.plonq == 2 ? 'active' : ''" @click="plonq(2)">PlonqX</div>
                    </div> -->
                    <div class="form-all">
                    <!-- <div class="form-check">
                        <p class="form-check-label">Выберите устройства</p>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" v-model="form.plonq" type="checkbox" id="inlineCheckbox1" value="0">
                            <label class="form-check-label" for="inlineCheckbox1">PLONQ</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" v-model="form.plonq" type="checkbox" id="inlineCheckbox2" value="1">
                            <label class="form-check-label" for="inlineCheckbox2">PLONQX</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" v-model="form.plonq" type="checkbox" id="inlineCheckbox3" value="2">
                            <label class="form-check-label" for="inlineCheckbox3">PLONQA</label>
                        </div>                    
                    </div> -->
                    <div class="form-check-retailers">
                        <p class="form-check-label">Выберите розничные сети</p>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" v-model="form.retailers" type="radio" id="inlineCheckbox1" value="Точка продаж" checked>
                            <label class="form-check-label" for="inlineCheckbox1">Точка продаж</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" v-model="form.retailers" type="radio" id="inlineCheckbox2" value="Перекресток">
                            <label class="form-check-label" for="inlineCheckbox2">Перекресток</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" v-model="form.retailers" type="radio" id="inlineCheckbox3" value="ЕКА">
                            <label class="form-check-label" for="inlineCheckbox3">ЕКА</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" v-model="form.retailers" type="radio" id="inlineCheckbox4" value="Спар">
                            <label class="form-check-label" for="inlineCheckbox4">Спар</label>
                        </div> 
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" v-model="form.retailers" type="radio" id="inlineCheckbox5" value="ЛУКОЙЛ">
                            <label class="form-check-label" for="inlineCheckbox5">ЛУКОЙЛ</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" v-model="form.retailers" type="radio" id="inlineCheckbox5" value="Азбука вкуса">
                            <label class="form-check-label" for="inlineCheckbox5">Азбука вкуса</label>
                        </div>                           
                    </div>
                    </div>
                    <div class="btn-group">
                        <div class=""><label><input type="checkbox" v-model="form.sobirator"><span>Собиратор</span></label></div>
                        <a href="#cancel" @click="close" class="btn-cancel">Отмена</a>
                        <a href="#save" class="btn-save" @click="createAddress"><img src="../assets/img/check-white.svg">Создать адрес</a>
                    </div>
                </div>
                <div class="content-map" v-if="(isMobile() && tabs.id == 1) || !isMobile()">
                    <div class="label-text">Уточнить местоположение адреса</div>
                    <div class="map">
                        <div class="map-content" id="map-content"></div>
                        <div class="map-label">
                            <div class="label-top">Yandex.MAPS</div>
                            <div class="label-bottom">2GIS</div>
                        </div>
                        <div class="map-gis" id="map-gis">
                            <div class="gis-bg"></div>
                            <div class="gis-content" id="gis-content" style="width: 100%; height: 100%;"></div>
                        </div>

                        <div class="map-close" v-if="!form.address && !form.coords">
                            <div class="close-content">
                                <img src="../assets/img/pin-gold.svg">
                                <span>Для уточнения<br>заполните адрес</span>
                            </div>
                        </div>
                        <img src="../assets/img/map.png" class="img-close" v-if="!form.address && !form.coords">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "vuex"
import { computed, ref, reactive, toRaw, onBeforeMount} from "vue"
import axios from 'axios'

import { debounce } from 'debounce'
import IMask from 'imask'

export default {
    name: 'windowAdd',
    setup() {
        const store = useStore();
        const close = () => {
            store.state.windowAddStatus = false
        }

        function initMap (selectCoords) {
            let map = new ymaps.Map('map-content', {
                    center: selectCoords,
                    zoom: 17,
                    controls: []
                }) 

            let myPlacemark = new ymaps.Placemark(selectCoords, null, {
                preset: 'islands#blueDotIcon',
                draggable: true
            });

            /* Событие dragend - получение нового адреса */
            myPlacemark.events.add('dragend', function(e){
                let coord = e.get('target').geometry.getCoordinates();
                form.coords = coord.join(' ')

                document.querySelector('#map-gis').innerHTML = `<div class="gis-bg"></div>
                <div class="gis-content" id="gis-content" style="width: 100%; height: 100%;"></div>`
                initGis(coord);
            });
		

            map.geoObjects.add(myPlacemark)
        }

        function initGis (selectCoords) {
            DG.then(function() {
                let map = DG.map('gis-content', {
                    center: selectCoords,
                    zoom: 17,
                    fullscreenControl: false,
                    zoomControl: false
                });

                DG.marker(selectCoords).addTo(map).bindPopup('I am a popup!');
            });
        }

        const suggestList = ref(null)
        const ddSearch = (value) => {
            let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
            let token = "dede3dda912a4a8174625e9df8d6b41066439061";
            let query = {"query": value, "locations": [{"country": "*"}]}

            let config = {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                }
            }

            axios.post(url, query, config)
            .then((response) => {
                let data = response.data
                
                if (data.suggestions) {
                    suggestList.value = data.suggestions
                }
            })
        }

        const searchSuggest = debounce(function (e) {
            if(e.target.value.length > 0) {
                ddSearch(e.target.value)
            } else {
                suggestList.value = null
            }
        }, 500)

        const userEmail = computed(() => store.state.userEmail)
    
        const tabs = reactive({
            id: 0
        })
    
        const setTab = (tab_id) => {
            tabs.id = tab_id
            
            setTimeout(() => {
                if (tab_id == 1) {
                    document.querySelector('#map-content').innerHTML = ''
                    document.querySelector('#map-gis').innerHTML = `<div class="gis-bg"></div>
                        <div class="gis-content" id="gis-content" style="width: 100%; height: 100%;"></div>`
                    ymaps.ready(initMap(form.coords.split(' ')));
                    initGis(form.coords.split(' '));
                }
            }, 50)
        }

        const isMobile = () => {
            if (window.innerWidth < 979)
                return true
        }

        const workTab = ref(2)
        const setWork = (id) => {
            workTab.value = id

            if (id == 0) {
                let maskElementPT = document.querySelector('#work-pn-pt')
                let maskPT = IMask(maskElementPT, {mask: '00:00 - 00:00'})
                let maskElementVS = document.querySelector('#work-sb-vs')
                let maskVS = IMask(maskElementVS, {mask: '00:00 - 00:00'})
            }
        }

        const form = reactive({
            address: null,
            index: null,
            country: null,
            region: null,
            city: null,
            street: null,
            building: null,
            coords: null,
            statusAddress: null,
            status_id: 0,
            label: null,
            agent: null,
            fias_id: null,
            kladr_id: null,
            phone: null,
            comment: null,
            notes: null,
            userEmail: userEmail.value,
            code: null,
            division: null,
            company: null,
            sobirator: null,
            plonq: [0],
            retailers: 'Точка продаж'
        })

        const setStatus = (status_id) => {
            form.status_id = status_id
        }
        const plonq = (plonq) => {
            form.plonq = plonq
        }
        const retailers = (retailers) =>{
            form.retailers = retailers
        }
        const showMessageStatus = (message, status, timeout) => {
            store.state.notifyMessage = message
            store.state.isNotifyMessageError = status 

            setTimeout(() => {
                store.state.notifyMessage = null
                store.state.isNotifyMessageError = false
            }, timeout)
        }

        const createAddress = () => {
            form.sobirator = form.sobirator == 1 ? true : false
            if (form.coords != null) {
                axios.post(process.env.VUE_APP_SERVER + '/api/address', toRaw(form)).then((response) => {
                    console.log(form)
                    let result = response.data
                    if (result['error']) {
                        //alert(result['message'])
                        showMessageStatus('Адрес уже существует', true, 2000);
                    } else {
                        close()
                        showMessageStatus(userEmail, false, 2000);
                        //store.dispatch('getAddress')
                        store.dispatch('getAddressLast')
                    }
                })
            } else {
                showMessageStatus('Координаты обязательны для заполнения', true, 2500);
            }
        }

        const setAddress = (suggest) => {
            suggestList.value = null
            suggest.data.block = suggest.data.block ? ' к ' + suggest.data.block : ''
            let building = 'д ' + suggest.data.house + suggest.data.block
            let statusAddress = (status) => {
                let statusResult = null
                switch (status) {
                    case "0": statusResult = 'Точные координаты'; break;
                    case "1": statusResult = 'Ближайший дом'; break;
                    case "2": statusResult = 'Улица'; break;
                    case "3": statusResult = 'Населенный пункт'; break;
                    case "4": statusResult = 'Город'; break;
                    case "5": statusResult = 'Координаты не определены'; break;
                }
                return statusResult
            }

            form.address = suggest.unrestricted_value
            form.country = suggest.data.country
            form.region = suggest.data.region
            form.city = suggest.data.city ? suggest.data.city : suggest.data.settlement
            form.street = suggest.data.street
            form.building = building
            form.index = suggest.data.postal_code
            form.coords = suggest.data.geo_lat + ' ' + suggest.data.geo_lon
            form.statusAddress = statusAddress(suggest.data.qc_geo)
            form.fias_id = suggest.data.fias_code
            form.kladr_id = suggest.data.kladr_id

            const selectCoords = [suggest.data.geo_lat, suggest.data.geo_lon]

            if (!isMobile()) {
                document.querySelector('#map-content').innerHTML = ''
                document.querySelector('#map-gis').innerHTML = `<div class="gis-bg"></div>
                    <div class="gis-content" id="gis-content" style="width: 100%; height: 100%;"></div>`
                ymaps.ready(initMap(selectCoords));
                initGis(selectCoords);
            }
        }

        const setHandCoords = () => {
            form.coords = form.coords.replace(',', '')
            document.querySelector('#map-content').innerHTML = ''
            document.querySelector('#map-gis').innerHTML = `<div class="gis-bg"></div>
        <div class="gis-content" id="gis-content" style="width: 100%; height: 100%;"></div>`
            ymaps.ready(initMap(form.coords.split(' ')));
            initGis(form.coords.split(' '));
        }
        return {
            close,
            form,
            createAddress,
            ddSearch,
            suggestList,
            setAddress,
            searchSuggest,
            setStatus,
            plonq,
            retailers,
            setHandCoords,
            tabs,
            setTab,
            isMobile,
            setWork,
            workTab
        }}}
</script>
<style lang="scss">
.form-all{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    >.form-check-retailers{
    max-width: 500px;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    margin-left: 5px;
        >p{
            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
            font-size: 14px;
            margin-left: 5px;
            width: 500px;
            display: flex;
            justify-content: flex-end;
        }
    }
}
        .form-check-inline{
            border: none;
            margin-top: 5px;
            margin-left: 2px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
        }
</style>