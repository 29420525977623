<template>
  <transition-group name="fade">
    <div class="settings">
        <div class="close-settings" @click="closeSettings"><img src="../assets/img/close-window.svg"></div>
        <div class="settings-wrapper">
            <div class="menu">
                <ul>
                    <li v-if="accessType == 3 || accessType == 1" :class="settingsActiveTab == 'settings' ? 'active' : ''" @click="setSettingsActiveTab('settings')"><a href='#'><img class="icon" src="../assets/img/settings-color.svg">Export Excel</a></li>
                    <li v-if="accessType == 2 || accessType == 1"  :class="settingsActiveTab == 'data' ? 'active' : ''" @click="setSettingsActiveTab('data')"><a href='#'><img class="icon" src="../assets/img/cloud-server.svg">hr jobs</a></li>
                    <li v-if="accessType == 1" :class="settingsActiveTab == 'invites' ? 'active' : ''" @click="setSettingsActiveTab('invites')"><a href='#'><img class="icon" src="../assets/img/group.svg">Приглашения</a></li>
                    <li v-if="accessType == 1" :class="settingsActiveTab == 'feed' ? 'active' : ''" @click="setSettingsActiveTab('feed')"><a href='#'><img class="icon" src="../assets/img/open-email.svg">Рассылки</a></li>
                    <li v-if="accessType == 1" :class="settingsActiveTab == 'logs' ? 'active' : ''" @click="setSettingsActiveTab('logs')"><a href='#'><img class="icon" src="../assets/img/list.svg">События</a></li>
                </ul>
            </div>
            <div class="content">
                <div id="account-settings" :class="settingsActiveTab == 'settings' ? 'active' : ''">
                    <div class="title">Export Excel</div>
                    <div class="area-content">
                        <excel/>
                    </div>
                </div>

                <div id="account-data" :class="settingsActiveTab == 'data' ? 'active' : ''">
                    <div class="title">hr jobs</div>
                    <div class="area-content">
                        <div class="data-stats">
                            <div class="stats-item soft-yellow">
                                <div class="counter">{{HrJobs.count}}</div>
                                <div class="description">Создано вакансий</div>
                            </div>
                        </div>
                        <div class="table">
                            <div class="wrapper">
                                <div class="title">Список вакансий</div>
                                <div class="buttonWrapper">
                                    <div class="button" @click="openModal">Добавить вакасию</div>
                                    <div class="button landing" :class="lang == 'ru' ? 'active' : '' " @click="getHrJobs('ru')">plonq.ru</div>
                                    <div class="button landing" :class="lang == 'en' ? 'active' : '' " @click="getHrJobs('en')">flonq.co.uk</div>
                                </div>
                                <div class="content-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <td>№</td>
                                            <td>Название</td>
                                            <td class="address-width">Адрес</td>
                                            <td>Описание</td>
                                            <td><img src="@/assets/img/pen-gray.svg"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in HrJobs" :key="index">
                                            <td>{{index+1}}</td>
                                            <td>{{item.JobTitle}}</td>
                                            <td>{{item.Address || item.address}}</td>
                                            <td>{{item.Description}}</td>
                                            <td @click="editJobs(item)">
                                                <div class="table-btn-edit"><img src="@/assets/img/pen-indigo.svg"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="account-invites" :class="settingsActiveTab == 'invites' ? 'active' : ''">
                    <div class="title">Приглашения</div>
                    <div class="area-content">
                        <div class="title-label" style="margin-top: 0px;">Новое приглашение</div>
                        <div class="invite-block">
                            <input type="text" disabled placeholder="S5EAZ8X">
                            <div class="btn-generate">Добавить</div>
                        </div>

                        <div class="title-label" style="margin-top: 52px;">Активные приглашения</div>
                        <div class="invite-list">
                            <div class="invite-item">
                                <div class="invite-code">4KM4V6Z</div>
                                <div class="invite-use">Использовано 0 раз</div>
                                <div class="invite-status">
                                    <div class="status-active active">Работает</div>
                                    <div class="status-disable">Отключено</div>
                                </div>
                            </div>

                            <div class="invite-item">
                                <div class="invite-code">815VVIJ</div>
                                <div class="invite-use">Использовано 0 раз</div>
                                <div class="invite-status">
                                    <div class="status-active">Работает</div>
                                    <div class="status-disable active">Отключено</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="account-feed" :class="settingsActiveTab == 'feed' ? 'active' : ''">
                    <div class="title">Рассылки</div>
                    <div class="area-content">
                        <div class="title-label" style="margin-top: 0px;">Подписка на замечания</div>
                        <div class="feed-area">
                            <div class="region-list">
                                <div class="title-list">Выбор областей</div>
                                <div class="search-region-group">
                                    <div class="search-icon"><img src="../assets/img/magnifying-glass.svg"></div>
                                    <input type="text" class="search-region" placeholder="Московская область" novalidate autocomplete="off">
                                </div>
                                <div class="item-list">
                                    <div class="item">
                                        <div class="check">
                                            <div class="checkbox active"></div>
                                            <span>Санкт-Петербург</span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="check">
                                            <div class="checkbox"></div>
                                            <span>Уральский район</span>
                                        </div>
                                    </div>

                                    <div class="item">
                                        <div class="check">
                                            <div class="checkbox"></div>
                                            <span>Ленинградский район</span>
                                        </div>
                                    </div>

                                    <div class="item">
                                        <div class="check">
                                            <div class="checkbox"></div>
                                            <span>Уральский район</span>
                                        </div>
                                    </div>

                                    <div class="item">
                                        <div class="check">
                                            <div class="checkbox"></div>
                                            <span>Ленинградский район</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="region-added">
                                <div class="item-add">
                                    Ленинградский район
                                    <div class="icon"><img src="../assets/img/close-window.svg"></div>
                                </div>
                            </div>

                            <div class="account-list">
                                <div class="title-list">Выбор аккаунта для рассылки</div>
                                <div class="account-item">
                                    <div class="icon"><img src="../assets/img/send.svg"></div>
                                    <span>example@plonq.ru (Мой аккаунт)</span>
                                </div>
                            </div>

                            <div class="title-label-small" style="margin-top: 36px;">Интервал отправки</div>

                            <div class="interval">
                                <div class="day-list">
                                    <div class="item">
                                        <div class="check">
                                            <div class="checkbox"></div>
                                            <span>Понедельник</span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="check">
                                            <div class="checkbox"></div>
                                            <span>Вторник</span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="check">
                                            <div class="checkbox"></div>
                                            <span>Среда</span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="check">
                                            <div class="checkbox"></div>
                                            <span>Четверг</span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="check">
                                            <div class="checkbox"></div>
                                            <span>Пятница</span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="check">
                                            <div class="checkbox"></div>
                                            <span>Суббота</span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="check">
                                            <div class="checkbox"></div>
                                            <span>Воскресенье</span>
                                        </div>
                                    </div>

                                    <div class="time">
                                        <input type="time" placeholder="Время" value="09:00">
                                    </div>
                                </div>
                            </div>

                            <div class="btn-save">Создать рассылку</div>
                        </div>
                    </div>
                </div>

                <div id="account-logs" :class="settingsActiveTab == 'logs' ? 'active' : ''">
                    <div class="title">События</div>
                    <div class="area-content">
                        <div class="title-label" style="margin-top: 0px;">Список событий и действий</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition-group>
<div ref="modal" class="modal">
    <div class="modalWrapper">
        <div class="header">
            <p>Название</p> 
            <div class="close" @click="openModal"><img src="@/assets/img/close-window.svg"></div>
        </div>
        <div class="main">
            <div class="address">
                <p>Адрес</p>
                <input v-model="form.address" placeholder="Адрес" type="text">
            </div>
            <div class="payingSalary">
                <p>Зарплата</p>
                <input v-model="form.payingSalary" placeholder="Зарплата" type="number">
            </div>
            <div class="left">
                <div class="Department list">
                    <p>Департамент</p>
                    <input v-model="form.Department" placeholder="Департамент" type="text">
                </div>
                <div class="JobTitle list">
                    <p>Название должности</p>
                    <input v-model="form.JobTitle" placeholder="Название должности"  type="text">
                </div>
                <div class="Description list">
                    <p>Описание</p>
                    <input v-model="form.Description" placeholder="Описание" type="text">
                </div>
            </div>
            <div class="right">
                <div class="city list">
                    <p>Город</p>
                    <input v-model="form.city" placeholder="Город"  type="text">
                </div>
                <div class="Section list">
                    <p>Раздел</p>
                    <div>                    
                        <label for="s1">plonq.ru</label>
                        <input v-model="form.section" id="s1" value="ru" type="radio">
                    </div>
                    <div>
                        <label for="s2">flonq.co.uk</label>
                        <input v-model="form.section" id="s2" value="en"   type="radio">
                    </div>
                </div>
                <div class="Country list">
                    <p>Страна</p>
                    <input v-model="form.country" placeholder="Страна" type="text">
                </div>
            </div>
            <div id="FullDescription" class="FullDescription">
                <ckeditor v-model="form.FullDescription"></ckeditor>
            </div>
        </div>
        <div class="footer">
            <div class="buttonAdd add" @click="addNewJobs">Добавить</div>
            <div class="buttonClose close" @click="openModal">Отмена</div>
            <div v-if="form.id" class="delet" @click="deleteJobs">Удалить</div>
        </div>
    </div>
</div>
</template>
<script>
import { useStore } from "vuex"
import { computed, ref, onMounted, toRaw, reactive} from "vue"
import { debounce } from 'debounce'
import Excel from '../components/Excel.vue';
import axios from "axios";
import { access } from "fs";

export default {
    name: 'Main',
    setup() {
        const HrJobs = ref([])
        const modal = ref()
        console.log(modal)
        const openModal = ()=>{
            modal.value.classList.toggle('active')
            form.Department = null
            form.JobTitle = null
            form.Description = null
            form.FullDescription = 'Полное описание'
            form.country = null
            form.city = null
            form.section = 'ru'
            form.address = null
            form.payingSalary = null
            form.id = null
        }
        const editJobs = (item)=>{
            console.log(item);
            modal.value.classList.toggle('active')
            form.Department = item.Department
            form.JobTitle = item.JobTitle
            form.Description = item.Description
            form.FullDescription = item.FullDescription
            form.country = item.country || item.Country
            form.city = item.city || item.City
            form.section = item.section || item.Section
            form.address = item.address || item.Address
            form.payingSalary = item.payingSalary
            form.id = item._id
        }
        const addNewJobs = ()=>{
            console.log(form)
            let url
            if(form.id === null)
                url = '/api/hrjobs'
            else
                url = '/api/hrjobs/edit'
            axios.post(process.env.VUE_APP_SERVER + url, toRaw(form)).then((response) => {
                console.log(response)
                modal.value.classList.toggle('active')
                form.Department = null
                form.JobTitle = null
                form.Description = null
                form.FullDescription = 'Полное описание'
                form.country = null
                form.city = null
                form.section = null
                form.address = null
                form.payingSalary = null
                form.id = null
            })
        }
        const form = reactive({
            Department: null,
            JobTitle: null,
            Description: null,
            FullDescription: 'Полное описание',
            country: null,
            city: null,
            section: null,
            address: null,
            payingSalary: null,
            id: null
        })
        const deleteJobs = () =>{
            console.log(form)
             axios.post(process.env.VUE_APP_SERVER + '/api/hrjobs/delete', toRaw(form)).then((response) => {

                modal.value.classList.toggle('active')
                form.Department = null
                form.JobTitle = null
                form.Description = null
                form.FullDescription = 'Полное описание'
                form.country = null
                form.city = null
                form.section = null
                form.address = null
                form.payingSalary = null
                form.id = null
            })
        }
        const lang = ref(null)
        const getHrJobs = async (langs) => {
            console.log(lang)
            lang.value = langs.toString()
            try {
                await axios({
                    url: process.env.VUE_APP_SERVER +`/api/hrjobs?lang=${langs}`,
                    method: 'get',
                    timeout: 10000,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then(async (response) => {
                    HrJobs.value = response.data.data
                    HrJobs.value.count = response.data.data.length
                    console.log(HrJobs.value);

                })
            } catch (err) {
                console.error(err)
            }
        }
        getHrJobs('ru')
        const store = useStore();

        const UserKolvo = ref(125)
        const preloaderStatus = computed(() => store.state.preloaderStatus)

        const tabIndexActive = ref(0)
        const setTabIndex = (id) => {
            if (!id)
                tabIndexActive.value = 0

            tabIndexActive.value = id
        }

        const windowAddStatus = computed(() => store.state.windowAddStatus)
        const windowEditStatus = computed(() => store.state.windowEditStatus)
        const exportStatus = computed(() => store.state.exportStatus)

        const searchInput = ref(null)
        const searchSetActive = () => { store.state.searchActiveStatus = true }
        const searchOutActive = () => { store.state.searchActiveStatus = false }
        const searchActive = computed(() => store.state.searchActiveStatus)

        const settingsActiveTab = ref();
        const loadpage = (acess) =>{
            console.log(acess)
            if(acess == 2)
                settingsActiveTab.value = 'data'
            if(acess == 3)
                settingsActiveTab.value = 'settings'
        }
        const setSettingsActiveTab = (tab) => {
            switch (tab) {
                case 'settings': settingsActiveTab.value = 'settings'; break;
                case 'data': settingsActiveTab.value = 'data'; break;
                case 'invites': settingsActiveTab.value = 'invites'; break;
                case 'feed': settingsActiveTab.value = 'feed'; break;
                case 'logs': settingsActiveTab.value = 'logs'; break;
                default: '';
            }
        }

        
        const logout = () => {
            store.dispatch('LOGOUT')
        }
        const admin = () => {
            store.dispatch('ADMIN')
        }
        const userEmail = computed(() => {
            return store.state.userEmail
        })
        const accessType = computed(() => {
            if(store.state.accessType)
                loadpage(store.state.accessType)
            return store.state.accessType
        })

        const usersList = computed(() => {
            return store.state.usersList
        })

        const getAccessType = computed(() => {
            return store.state.accessType
        })

        const openWindowEdit = (_id) => {
            store.state.windowEditStatus = true
            store.dispatch('LOAD_ADDRESS_BY_ID', _id)
        }

        const searchSelectAddress = (id) => {
            searchOutActive()
            openWindowEdit(id)
            searchInput.value = null
        }

        const searchListComputed = computed(() => {
            return store.state.searchList
        })

        const searchFormDebounce = debounce(function (e) {
            if(e.target.value.length > 0) {
                store.dispatch('SEARCH_FORM', {'query': e.target.value})
            } else {
                store.state.searchList = []
            }
        }, 500)


        /* Статусы в таблице */
        const getStatusClass = (status_id) => {
            switch (status_id) {
                case 0: return 'good'; break;
                case 1: return 'warning'; break;
                case 2: return 'error'; break;
                case 3: return 'disabled'; break;
            }
        }

        const getStatusName = (status_id) => {
            switch (status_id) {
                case 0: return 'Проверен'; break;
                case 1: return 'Уточнить'; break;
                case 2: return 'Ошибка'; break;
                case 3: return 'Отключен'; break;
            }
        }

        /** SETTINGS PROFILE */
        const settingMessageSuccess = ref(null)
        const settingMessageError = ref(null)
        
        const settingForm = reactive({
            id: '',
            name: '',
            surname: '',
            email: '',
            position: '',
            invite: ''
        });

        const settingFormPassword = reactive({
            password: '',
            passwordConfirm: '',
        });

        const btnSettingSaveProfile = () => {
            //save settingForm
            if (settingForm.name.length > 0 && settingForm.surname.length > 0 && settingForm.position.length > 0) {
                settingMessageError.value = ''
                settingMessageSuccess.value = 'Профиль обновлен'

                btnSettingUpdatePassword();
                //
            } else {
                settingMessageError.value = 'Для сохранения заполните все поля'
            }
        }

        const btnSettingUpdatePassword = () => {
            // save Password
            if (settingFormPassword.password.length > 0 && settingFormPassword.passwordConfirm.length > 0) {
                if (settingFormPassword.password == settingFormPassword.passwordConfirm) {
                    // ACTION SAVE
                    settingMessageSuccess.value = 'Установлен новый пароль'
                } else {
                    settingMessageError.value = 'Пароли не совпадают'
                }
            } else {
                settingMessageError.value = '';
            }
        }

        const btnSettingSaveAll = () => {
            btnSettingSaveProfile()
        }

        /** END SETTINGS PROFILE */

        const statusSettingsWindow = ref(false);
        const closeSettings = () => {
            window.location.href = "https://new.sponq.ru/"
            }
        const openSettings = () => statusSettingsWindow.value = true

        const isMobile = () => {
            if (window.innerWidth < 979)
                return true
        }

        const getFeedbackCount = computed(() => {
            return store.state.feedback
        })

        const notifyMessage = computed(() => store.state.notifyMessage);
        const isNotifyMessageError = computed(() => store.state.isNotifyMessageError)
        
        return {
            deleteJobs,
            preloaderStatus,
            getHrJobs,
            addNewJobs,
            HrJobs,
            UserKolvo,
            windowAddStatus,
            windowEditStatus,
            openModal,
            exportStatus,
            searchInput,
            searchSetActive,
            searchActive,
            searchOutActive,
            admin,
            logout,
            form,
            modal,
            searchFormDebounce,
            searchListComputed,
            searchSelectAddress,
            getStatusClass,
            getStatusName,
            userEmail,
            accessType,
            usersList,
            lang,

            getAccessType,
            editJobs,
            isMobile,
            tabIndexActive,
            setTabIndex,
            getFeedbackCount,
            setSettingsActiveTab,
            settingsActiveTab,
            btnSettingSaveAll,
            settingForm,
            settingFormPassword,
            btnSettingSaveProfile,
            btnSettingUpdatePassword,
            settingMessageError,
            settingMessageSuccess,
            statusSettingsWindow,
            closeSettings,
            openSettings,
            notifyMessage,
            isNotifyMessageError
        }
        
    },
        components: {
        Excel
    }
}
</script>
<style lang="scss" scoped>
#FullDescription{
    // height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.modal{
    position: fixed;
    background: rgba(0, 0, 0, 0.096);
    height: 100vh;
    width: 100vw;
    z-index: 99999;
    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    &.active{
        display: flex;
    }
    >.modalWrapper{
        background: white;
        width: 70vw;
        height: 94vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        >.header{
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            margin-top: 25px;
            >div{
                margin-right: 75px;
                height: 30px;
                width: 30px;
                cursor: pointer;
                background: rgba(0, 0, 0, 0.068);
                border-radius: 50%;
                display: flex;
                overflow: hidden;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
            }
            p{
                margin-left: 75px;
                -webkit-font-smoothing: antialiased;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
                font-family: "SF Pro Display";
                font-style: normal;
                font-weight: 600;
                font-size: 21px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: #000000;
            }
        }
        >.main{
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            height: 100%;
            >.address{
                width: calc(50% - 75px);
                margin-left: 75px;
                p{
                    -webkit-font-smoothing: antialiased;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
                    font-family: "SF Pro Display";
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 17px;
                    letter-spacing: 0.36px;
                    color: #657786;
                    margin-bottom: 8px;
                }
                input{
                    -webkit-font-smoothing: antialiased;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
                    appearance: none;
                    width: 70%;
                    height: 38px;
                    background: #F5F7FA;
                    border: 1px solid rgba(102, 16, 242, 0.03);
                    box-sizing: border-box;
                    border-radius: 6px;
                    padding: 0px 12px;
                    font-family: "SF Pro Display";
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 17px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.36px;
                    color: #657786;
                    outline: none;
                }
            }
            >.payingSalary{
                width: 50%;
                p{
                    -webkit-font-smoothing: antialiased;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
                    font-family: "SF Pro Display";
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 17px;
                    letter-spacing: 0.36px;
                    color: #657786;
                    margin-bottom: 8px;
                }
                input{
                    -webkit-font-smoothing: antialiased;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
                    appearance: none;
                    width: 70%;
                    height: 38px;
                    background: #F5F7FA;
                    border: 1px solid rgba(102, 16, 242, 0.03);
                    box-sizing: border-box;
                    border-radius: 6px;
                    padding: 0px 12px;
                    font-family: "SF Pro Display";
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 17px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.36px;
                    color: #657786;
                    outline: none;
                }
            }
            >.left{
                margin-left: 75px;
                width: calc(50% - 75px);
                p{
                    -webkit-font-smoothing: antialiased;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
                    font-family: "SF Pro Display";
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 17px;
                    letter-spacing: 0.36px;
                    color: #657786;
                    margin-bottom: 8px;
                }
                input{
                    -webkit-font-smoothing: antialiased;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
                    appearance: none;
                    width: 70%;
                    height: 38px;
                    background: #F5F7FA;
                    border: 1px solid rgba(102, 16, 242, 0.03);
                    box-sizing: border-box;
                    border-radius: 6px;
                    padding: 0px 12px;
                    font-family: "SF Pro Display";
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 17px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.36px;
                    color: #657786;
                    outline: none;
                }

            }
            >.right{
                width: 50%;
                .Section{
                    display: flex;
                    flex-wrap: wrap;
                    p{
                        width: 100%;
                        user-select: none;
                    }
                    div{
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        align-content: center;
                        justify-content: center;
                        align-items: center;
                        margin-right: 25px;
                        cursor: pointer;
                        >input{
                            cursor: pointer;
                        }
                        >label{
                         -webkit-font-smoothing: antialiased;
                        -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
                        font-family: "SF Pro Display";
                        font-weight: 400;
                        font-size: 13px;
                        color: #657786;
                        // margin-bottom: 8px;
                        user-select: none;
                        cursor: pointer;
                        }
                    }
                }
                p{
                    -webkit-font-smoothing: antialiased;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
                    font-family: "SF Pro Display";
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 17px;
                    letter-spacing: 0.36px;
                    color: #657786;
                    margin-bottom: 8px;
                }
                input[type="text"]{
                    -webkit-font-smoothing: antialiased;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
                    appearance: none;
                    width: 70%;
                    height: 38px;
                    background: #F5F7FA;
                    border: 1px solid rgba(102, 16, 242, 0.03);
                    box-sizing: border-box;
                    border-radius: 6px;
                    padding: 0px 12px;
                    font-family: "SF Pro Display";
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 17px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.36px;
                    color: #657786;
                    outline: none;
                }
            }
        }
        >.footer{
            display: flex;
            width: 500px;
            justify-content: space-evenly;
            padding-bottom: 35px;
            padding-top: 20px ;
            >div{
                height: 45px;
                width: 140px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0.123);
                border: #000;
                border-radius: 25px;
                color: white;
                cursor: pointer;
                user-select: none;
                &:active{
                    transform: scale(.98);
                }
                &.add{
                    background: #2f7526;
                }
                &.close{
                    background: #444;
                }
            }
        }
    }
}
.table{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    >.wrapper{
        width: 100%;
        height: 100%;
        >.buttonWrapper{
            height: 50px;
            width: 100%;
            background: rgba(0, 0, 0, 0.048);
            border-radius: 50px;
            display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: flex-start;
                align-items: center;

            >.button{
                height: 40px;
                width: 150px;
                border-radius: 10px;
                background: #34A853;
                margin-left: 25px;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                color: white;
                font-size: 14px;
                cursor: pointer;
                user-select: none;
                &.landing{
                    background: #444;
                    &.active{
                    cursor: default;
                    background: rgb(18, 99, 52);
                    }
                }
                &:active{
                    transform: scale(.97);
                }
            }
        }
        >.title{
            font-size: 18px;
            display: flex;
            align-content: center;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        >.listadd{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: flex-start;
            >ul{
                width: 100%;
                height: 50px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
                >li{
                    margin-right: 40px;
                }
            }
        }
    }
}
ul,
li {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  //font-family: $gpm;
  font-family: 'Gotham Pro';
  font-weight: normal;
  margin: 0;
}
#account-data > div.area-content > div.table > div > div.content-table > table > thead > tr > td:nth-child(4){
    padding-right: 75px;
}
.content-table {
            width: 100%;
            min-height: 300px;
            
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            > table {
                width: 606px;
                border: 0px;
                border-collapse: collapse;
                display: flex;
                flex-direction: column;
                > thead > tr > td {
                    height: 55px;

                    font-family: 'SF Pro Display';
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.22px;
                    font-variant: small-caps;
                    text-transform: lowercase;

                    color: #657786;
                    
                    padding: 0px 25px;

                    border: 0px;
                    margin: 0px;

                    position: relative;

                    &.address-width {
                        min-width: 80px;
                        padding-right: 100px;
                    }

                    > span {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        > img {
                            width: 18px;
                            margin-right: 12px;
                            float: left;
                        }
                    }

                    > .user-list {
                        width: 270px;
                        min-height: 100px;
                        background-color: #fff;
                        
                        position: absolute;
                        top: 54px;
                        border-radius: 6px;

                        padding: 12px 24px 24px;;
                        z-index: 100;

                        > .title {
                            font-family: 'SF Pro Display';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;

                            letter-spacing: 0.2px;
                            
                            color: #000000;
                            margin-bottom: 20px;

                            text-align: left;
                        }

                        > .item-list {
                            width: auto;
                            height: 42px;
                            padding: 0px 16px;

                            display: flex;
                            align-items: center;
                            
                            transition: all 0.2s ease-in;
                            border-radius: 6px;

                            cursor: pointer;

                            > .check {
                                width: 16px;
                                height: 16px;
                                border-radius: 4px;
                                background-color: #fff;

                                border: 1px rgba(101, 119, 134, 0.2) solid;

                                margin-right: 16px;
                                transition: all 0.1s ease-in;

                                &.active {
                                    background: rgba(52, 168, 83, 1);
                                    background-image: url('/img/check-white.svg');
                                    background-repeat: no-repeat;
                                    background-size: 60%;
                                    background-position: center;
                                    border: 1px #fff solid;
                                }
                            }

                            > .user-info {
                                font-family: 'SF Pro Display';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                color: #000;

                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: center;
                                border-radius: 6px;

                                > span {
                                    font-size: 12px;
                                    color: #657786;
                                }
                            }

                            &:nth-of-type(odd) {
                                background: rgba(245, 247, 250, 0.5);
                            }

                            &:hover {
                                background: rgba(245, 247, 250, 1);
                            }
                        }

                        > .btn-confirm {
                            width: auto;
                            height: 42px;

                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 6px;

                            color: #34A853;
                            background: rgba(52, 168, 83, 0.1);
                            transition: all 0.2s ease-in;

                            margin-top: 16px;
                            text-decoration: none;

                            > img {
                                height: 14px;
                            }

                            &:hover {
                                background: rgba(52, 168, 83, 0.2);
                            }
                        }
                    }

                    > .user-bg {
                        position: fixed;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        background: rgba(101, 119, 134, 0.25);

                        z-index: 50;
                        transition: all 0.3s ease;
                    }
                }

                > thead {
                    border: 0px;
                }

                > tbody {
                    border: 0px;
                }

                > tbody > tr {
                    border: 0px;
                    transition: all 0.2s ease;

                    &:hover {
                        background: rgba(245, 247, 250, 1) !important;
                    }

                    &:nth-of-type(odd) {
                        background: rgba(245, 247, 250, 0.5);
                    }
                }

                > tbody > tr > td {
                    height: 60px;
                    font-family: 'SF Pro Display';
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;

                    text-align: center;
                    letter-spacing: 0.22px;

                    color: #444;

                    border: 0px;
                    margin: 0px;
                    padding: 0px 12px;

                    @media all and (max-width: 979px) {
                        padding: 0px 16px;
                        font-size: 12px;
                        //white-space: nowrap;
                    }

                    cursor: default;

                    > .tag-list {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;

                        padding: 14px 0px;

                        > .status-tag {
                            padding: 4px 8px;
                            background-color: rgba(101, 119, 134, 0.075);
                            color: #000;
                            margin: 4px 4px;
                            font-weight: 400;
                            font-size: 13px;
                            letter-spacing: .3px;
                            border-radius: 6px;
                            display: block;
                            font-family: 'SF Pro Display' !important;
                        }
                    }

                    > .table-btn-edit {
                        width: 36px;
                        height: 28px;
                        border-radius: 4px;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        background-color:rgba(102, 16, 242, 0.07);
                        margin: auto;

                        cursor: pointer;
                    }

                    > .table-btn-remove {
                        width: 36px;
                        height: 28px;
                        border-radius: 4px;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        background-color:rgba(235, 67, 53, 0.05);
                        margin: auto;

                        cursor: pointer;
                    }

                    > .label {
                        width: 84px;
                        height: 24px;
                        border-radius: 4px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        font-family: 'SF Pro Display';
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;

                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.22px;

                        margin: auto;

                        &.error {
                            background: rgba(235, 67, 53, 0.05);
                            color: #EB4335;
                        }

                        &.warning {
                            background: rgba(66, 133, 244, 0.1);
                            color: #4285F4;
                        }

                        &.good {
                            background: rgba(52, 168, 83, 0.1);
                            color: #34A853;
                        }

                        &.disabled {
                            background: rgba(101, 119, 134, 0.07);
                            color: rgba(101, 119, 134, 0.5);
                        }
                    }
                }
            }
        }
</style>